export const useBuilderStore = defineStore('builder', {
  state: () => {
    return {
      lang: null,
      agendaMaxDescriptionLength: 200,
      page: {
        presets: [
          {
            id: 'page-header-1',
            category: 'header',
            title: useT('abc664'),
          },
          {
            id: 'page-header-2',
            category: 'header',
            title: useT('abc665'),
          },
          {
            id: 'page-header-3',
            category: 'header',
            title: useT('abc666'),
          },
          {
            id: 'page-header-4',
            category: 'header',
            title: useT('abc667'),
          },

          {
            id: 'page-about-1',
            category: 'about',
            title: useT('abc568'),
          },
          {
            id: 'page-about-2',
            category: 'about',
          },
          {
            id: 'page-about-3',
            category: 'about',
            title: useT('abc668'),
          },
          {
            id: 'page-about-4',
            category: 'about',
          },
          {
            id: 'page-about-5',
            category: 'about',
          },

          {
            id: 'page-about-6',
            category: 'about',
            title: useT('abc669'),
          },
          {
            id: 'page-about-7',
            category: 'about',
          },
          {
            id: 'page-about-8',
            category: 'about',
          },

          {
            id: 'page-speakers-1',
            category: 'speakers',
          },
          {
            id: 'page-speakers-2',
            category: 'speakers',
          },
          {
            id: 'page-speakers-3',
            category: 'speakers',
          },
          {
            id: 'page-speakers-4',
            category: 'speakers',
          },

          {
            id: 'page-agenda-1',
            category: 'agenda',
            title: useT('abc670'),
          },
          {
            id: 'page-agenda-2',
            category: 'agenda',
          },
          {
            id: 'page-agenda-3',
            category: 'agenda',
          },

          {
            id: 'page-agenda-4',
            category: 'agenda',
            title: useT('abc671'),
          },
          {
            id: 'page-agenda-5',
            category: 'agenda',
          },
          {
            id: 'page-agenda-6',
            category: 'agenda',
          },

          {
            id: 'page-agenda-7',
            category: 'agenda',
            title: useT('abc672'),
          },

          {
            id: 'page-agenda-8',
            category: 'agenda',
            title: useT('abc673'),
          },

          {
            id: 'page-accommodations-1',
            category: 'accommodations',
          },
          {
            id: 'page-accommodations-2',
            category: 'accommodations',
          },
          {
            id: 'page-accommodations-3',
            category: 'accommodations',
          },
          {
            id: 'page-accommodations-4',
            category: 'accommodations',
          },

          {
            id: 'page-dresscode-1',
            category: 'dresscode',
          },
          {
            id: 'page-dresscode-2',
            category: 'dresscode',
          },
          {
            id: 'page-dresscode-3',
            category: 'dresscode',
          },

          {
            id: 'page-location-1',
            category: 'location',
            title: useT('abc674'),
          },

          {
            id: 'page-location-2',
            category: 'location',
            title: useT('abc675'),
          },

          {
            id: 'page-sponsors-1',
            category: 'sponsors',
          },
          {
            id: 'page-sponsors-2',
            category: 'sponsors',
          },
          {
            id: 'page-sponsors-3',
            category: 'sponsors',
          },
          {
            id: 'page-sponsors-4',
            category: 'sponsors',
          },
          {
            id: 'page-sponsors-5',
            category: 'sponsors',
          },

          {
            id: 'page-form-1',
            category: 'form',
          },
          {
            id: 'page-form-2',
            category: 'form',
          },

          {
            id: 'page-footer-1',
            category: 'footer',
            title: useT('abc676'),
          },

          {
            id: 'page-footer-2',
            category: 'footer',
            title: useT('abc677'),
          },
          {
            id: 'page-footer-3',
            category: 'footer',
          },

          {
            id: 'page-bar-1',
            category: 'bar',
            title: useT('abc678'),
          },
          {
            id: 'page-bar-2',
            category: 'bar',
          },
          {
            id: 'page-bar-3',
            category: 'bar',
          },

          {
            id: 'page-bar-4',
            category: 'bar',
            title: useT('abc392'),
          },

          {
            id: 'page-grid-1',
            category: 'grid',
            title: useT('abc679'),
          },
          {
            id: 'page-grid-2',
            category: 'grid',
          },
          {
            id: 'page-grid-3',
            category: 'grid',
          },
          {
            id: 'page-grid-4',
            category: 'grid',
            title: useT('abc680'),
          },
          {
            id: 'page-grid-5',
            category: 'grid',
          },
          {
            id: 'page-grid-6',
            category: 'grid',
          },
          {
            id: 'page-grid-7',
            category: 'grid',
          },
          {
            id: 'page-grid-8',
            category: 'grid',
          },

          {
            id: 'page-collapsible-1',
            category: 'collapsible',
          },
          {
            id: 'page-collapsible-2',
            category: 'collapsible',
          },

          {
            id: 'page-image-1',
            category: 'image',
            title: useT('abc392'),
          },
          {
            id: 'page-image-2',
            category: 'image',
          },
          {
            id: 'page-image-3',
            category: 'image',
            title: useT('abc681'),
          },

          {
            id: 'page-text-1',
            category: 'text',
          },
          {
            id: 'page-text-2',
            category: 'text',
          },
          {
            id: 'page-text-3',
            category: 'text',
          },
          {
            id: 'page-text-4',
            category: 'text',
          },
          {
            id: 'page-text-5',
            category: 'text',
          },
          {
            id: 'page-text-6',
            category: 'text',
          },
          {
            id: 'page-text-7',
            category: 'text',
          },

          {
            id: 'page-map-1',
            category: 'map',
            title: useT('abc674'),
          },

          {
            id: 'page-divider-1',
            category: 'divider',
          },
          {
            id: 'page-divider-2',
            category: 'divider',
          },
          {
            id: 'page-divider-3',
            category: 'divider',
          },
        ],

        categories: [
          {
            id: 'header',
            title: useT('abc682'),
            icon: 'image-03',
            isMain: true,
          },
          {
            id: 'about',
            title: useT('xyz174'),
            icon: 'type-01',
            isMain: true,
          },
          {
            id: 'speakers',
            title: useT('xyz158'),
            icon: 'users-02',
            isMain: true,
          },
          {
            id: 'agenda',
            title: useT('xyz157'),
            icon: 'calendar',
            isMain: true,
          },
          {
            id: 'accommodations',
            title: useT('abc683'),
            icon: 'home-02',
            isMain: true,
          },
          {
            id: 'dresscode',
            title: useT('abc684'),
            icon: 'custom/dresscode',
            isMain: true,
          },
          {
            id: 'location',
            title: useT('abc685'),
            icon: 'marker-pin-01',
            isMain: true,
          },
          {
            id: 'sponsors',
            title: useT('xyz164'),
            icon: 'building-07',
            isMain: true,
          },
          {
            id: 'form',
            title: useT('abc686'),
            icon: 'text-input',
            isMain: true,
          },
          {
            id: 'footer',
            title: useT('abc677'),
            icon: 'info-circle',
            isMain: true,
          },
          {
            id: 'bar',
            title: useT('abc687'),
            isMain: false,
            icon: 'menu-01',
          },
          {
            id: 'grid',
            title: useT('abc688'),
            icon: 'grid-01',
            isMain: false,
          },
          {
            id: 'collapsible',
            title: useT('abc689'),
            icon: 'custom/dots',
            isMain: false,
          },
          {
            id: 'image',
            title: useT('abc388'),
            icon: 'image-03',
            isMain: false,
          },
          {
            id: 'text',
            title: useT('abc538'),
            icon: 'custom/align-left',
            isMain: false,
          },
          {
            id: 'map',
            title: useT('abc674'),
            isMain: false,
            icon: 'map-01',
          },
          {
            id: 'divider',
            title: useT('abc690'),
            isMain: false,
            icon: 'custom/divider',
          },
        ],
      },
      email: {
        presets: [
          {
            id: 'email-image-1',
            category: 'image',
          },
          {
            id: 'email-image-2',
            category: 'image',
          },
          {
            id: 'email-image-3',
            category: 'image',
          },
          {
            id: 'email-image-4',
            category: 'image',
          },

          {
            id: 'email-text-1',
            category: 'text',
          },
          {
            id: 'email-text-2',
            category: 'text',
          },
          {
            id: 'email-text-3',
            category: 'text',
          },
          {
            id: 'email-text-4',
            category: 'text',
          },
          {
            id: 'email-text-5',
            category: 'text',
          },

          {
            id: 'email-button-1',
            category: 'button',
          },
          {
            id: 'email-button-2',
            category: 'button',
          },
          {
            id: 'email-button-3',
            category: 'button',
          },
          {
            id: 'email-button-4',
            category: 'button',
          },
          {
            id: 'email-button-5',
            category: 'button',
          },
          {
            id: 'email-button-6',
            category: 'button',
          },

          {
            id: 'email-list-1',
            category: 'list',
          },
          {
            id: 'email-list-2',
            category: 'list',
          },
          {
            id: 'email-list-3',
            category: 'list',
          },
          {
            id: 'email-list-4',
            category: 'list',
          },
          {
            id: 'email-list-5',
            category: 'list',
          },
          {
            id: 'email-list-6',
            category: 'list',
          },

          {
            id: 'email-divider-1',
            category: 'divider',
          },
          {
            id: 'email-divider-2',
            category: 'divider',
          },
          {
            id: 'email-divider-3',
            category: 'divider',
          },

          {
            id: 'email-map-1',
            category: 'map',
          },

          {
            id: 'email-guest-1',
            category: 'guest',
            hideInPlans: ['basic', 'professional'],
          },
          {
            id: 'email-guest-2',
            category: 'guest',
          },
          {
            id: 'email-salutation-1',
            category: 'salutation',
          },
        ],
        categories: [
          {
            id: 'image',
            title: useT('abc388'),
            icon: 'image-03',
          },
          {
            id: 'salutation',
            title: useT('abc567'),
            icon: 'edit-04',
          },
          {
            id: 'text',
            title: useT('xyz174'),
            icon: 'type-01',
          },
          {
            id: 'button',
            title: useT('abc604'),
            icon: 'custom/button',
          },
          {
            id: 'list',
            title: useT('abc691'),
            icon: 'list',
          },
          {
            id: 'divider',
            title: useT('abc690'),
            icon: 'custom/divider',
          },
          {
            id: 'map',
            title: useT('abc674'),
            icon: 'map-01',
          },
          {
            id: 'guest',
            title: useT('abc692'),
            icon: 'user-03',
          },
        ],
      },
      options: {
        imageSize: [
          {
            id: 'small',
            icon: 'custom/size-s',
          },
          {
            id: 'medium',
            icon: 'custom/size-m',
          },
          {
            id: 'large',
            icon: 'custom/size-l',
          },
        ],
        imageCrop: [
          {
            id: 'circle',
            text: useT('abc693'),
            icon: 'circle',
          },
          {
            id: 'square',
            text: useT('abc694'),
            icon: 'square',
          },
          {
            id: '4/3',
            text: useT('abc695'),
          },
          {
            id: '16/9',
            text: useT('abc696'),
          },
          {
            id: '3/4',
            text: useT('abc697'),
          },
          {
            id: 'original',
            text: useT('abc1083'),
          },
        ],
        spacer: [
          {
            id: 'none',
            icon: 'custom/size-none',
          },
          {
            id: 'small',
            icon: 'custom/size-s',
          },
          {
            id: 'medium',
            icon: 'custom/size-m',
          },
          {
            id: 'large',
            icon: 'custom/size-l',
          },
        ],
        alignment: [
          {
            id: 'left',
            icon: 'align-left',
          },
          {
            id: 'center',
            icon: 'align-center',
          },
          {
            id: 'right',
            icon: 'align-right',
          },
        ],
        arrangement: [
          {
            id: 'targetRight',
            icon: 'custom/align-left',
          },
          {
            id: 'targetLeft',
            icon: 'custom/align-right',
          },
        ],
        linkType: [
          {
            id: 'activePage',
            text: useT('abc698'),
            icon: 'check',
          },
          {
            id: 'denialPage',
            text: useT('abc699'),
            icon: 'x-close',
          },
          {
            id: 'guestAppAccess',
            text: useT('abc700'),
            icon: 'phone-01',
          },
          {
            id: 'registrationPage',
            text: useT('abc1070'),
            icon: 'monitor-01',
          },
          {
            id: 'addToCalendar',
            text: useT('xyz701'),
            icon: 'calendar-plus-02',
          },
          {
            id: 'customUrl',
            text: useT('abc702'),
            icon: 'globe-02-1',
          },
        ],
        contactLinkType: [
          {
            id: 'linkedin',
            text: useT('xyz703'),
            icon: 'custom/logo-linkedin',
            tags: ['linkedin.com'],
          },
          {
            id: 'facebook',
            text: useT('xyz704'),
            icon: 'custom/logo-facebook',
            tags: ['facebook.com', 'fb.com'],
          },
          {
            id: 'instagram',
            text: useT('xyz705'),
            icon: 'custom/logo-instagram',
            tags: ['instagram.com', 'instagr.am'],
          },
          {
            id: 'twitter',
            text: useT('xyz706'),
            icon: 'custom/logo-twitter',
            tags: ['twitter.com', 't.co'],
          },
          {
            id: 'web',
            text: useT('xyz707'),
            icon: 'globe-01-1',
          },
        ],
        daysMode: [
          {
            id: 'rows',
            text: useT('abc546'),
            icon: 'arrow-down',
          },
          {
            id: 'columns',
            text: useT('abc545'),
            icon: 'arrow-right',
          },
          {
            id: 'tabs',
            text: useT('abc708'),
            icon: 'columns-03',
          },
        ],
        itemContainer: [
          {
            id: 'none',
            text: useT('abc605'),
          },
          {
            id: 'box',
            text: useT('abc709'),
          },
        ],
        itemDecoration: [
          {
            id: 'icon',
            text: useT('abc554'),
          },
          {
            id: 'image',
            text: useT('abc388'),
          },
        ],
        iconPlacement: [
          {
            id: 'top',
            text: useT('abc710'),
          },
          {
            id: 'left',
            text: useT('abc711'),
          },
        ],
      },
      formBasicShowSubmitted: false,
    };
  },

  actions: {
    replacePresetTokens({ presetString, primaryColor, appearance, replaces }) {
      const event = useEventStore().event;
      let result = presetString;

      const escapeStringForJSON = (content) => String(content || '')
        .replaceAll(/\n/g, '<br>') // replace newlines
        .replaceAll(/\r/g, '\\r') // replace carriage returns
        .replaceAll(/\t/g, '\\t') // replace tabs
        .replaceAll(/\f/g, '\\f') // replace form feeds
        .replaceAll(/\v/g, '\\v') // replace vertical tabs
        .replaceAll(/\0/g, '\\0') // replace null bytes
        .replaceAll(/"/g, '\\"'); // replace double quotes

      if (replaces && Object.keys(replaces).length > 0) {
        Object.keys(replaces).forEach((key) => {
          result = result.replaceAll(key, escapeStringForJSON(replaces[`${key}`]));
        });
      }

      result = result.replaceAll('{primaryColor}', event?.plan?.config?.tier === 'basic' ? useTailwindConfig.colors.primary['500'] : (primaryColor || useWorkspaceStore().identity?.primaryColor))
        .replace(/{id}/g, () => nanoid(10))
        .replaceAll('{appearance}', appearance || 'light')
        .replaceAll('{eventTitle}', escapeStringForJSON(event?.general?.title))
        .replaceAll('{fullLocation}', event?.general?.location?.placeName ? escapeStringForJSON(`${event?.general?.location?.placeName}, ${event?.general?.location?.address}, ${event?.general?.location?.city}, ${event?.general?.location?.zipCode}, ${event?.general?.location?.country}`) : '')
        .replaceAll('{placeName}', escapeStringForJSON(event?.general?.location?.placeName))
        .replaceAll('{logo}', useWorkspaceStore().identity?.logo)
        .replaceAll('{contactEmail}', escapeStringForJSON(event?.general?.contactEmail))
        .replaceAll('"{emailTemplateId}"', null)
        .replaceAll('{workspaceName}', escapeStringForJSON(useWorkspaceStore().name))
        .replaceAll('{guestAppUrl}', `${useWorkspaceStore().getCustomOrigin(event)}/app/${event?._id}`);

      result = result.replaceAll('{startDate}', (match, index) => {
        const lang = _last(result.substring(0, index).match(/"_[a-z]{2}"/g))?.replace(/"/g, '').replace(/_/g, '');
        return getFormattedDate(
          event?.general?.startDate,
          {
            lang,
            format: 'LLL',
            timezone: event?.general?.timezone,
          },
        );
      });

      result = result.replaceAll('{fullDate}', (match, index) => {
        const lang = _last(result.substring(0, index).match(/"_[a-z]{2}"/g))?.replace(/"/g, '').replace(/_/g, '');
        return getFormattedDate(
          {
            start: event?.general?.startDate,
            end: event?.general?.endDate,
          },
          {
            lang,
            format: 'LLL',
            timezone: event?.general?.timezone,
          },
        );
      });

      return result;
    },
    async getPresetTemplate({ id, type, replaces }) {
      const templateString = await $fetch(`/presets/${type}/templates/${id}.json`)
        .then((response) => JSON.stringify(response))
        .catch(() => {
          console.warn('Preset template not found', { id, type });
          return '{}';
        });

      const presetTemplate = JSON.parse(this.replacePresetTokens({ presetString: templateString, replaces }));

      if (presetTemplate?.params && useWorkspaceStore().identity?.font) {
        presetTemplate.params.font = useWorkspaceStore().identity?.font;
      }

      presetTemplate.name = presetTemplate[`_${useEventStore().defaultLang}`]?.name || presetTemplate.name;

      return presetTemplate;
    },
    async getPresetSections({ id, template }) {
      const sectionsString = await $fetch(`/presets/${template?.type}/${id}.json`)
        .then((response) => JSON.stringify(response))
        .catch(() => {
          console.warn('Preset sections not found', { id, template });
          return '[]';
        });

      let sections = JSON.parse(this.replacePresetTokens({ presetString: sectionsString, primaryColor: template?.params?.primaryColor, appearance: template?.params?.appearance }));

      sections = sections.map((section) => {
        return {
          ...section,
          data: {
            hasRestrictedVisibility: false,
            visibleOnlyTo: [],
            ...section.data,
          },
        };
      });

      return sections;
    },

    isTranslated(item) {
      const langs = useEventStore().event?.general?.langs || [];
      let isTranslated = true;

      langs.forEach((lang) => {
        const modelValues = Object.values(item[`_${lang}`] || {}).filter((value) => !!value);

        if (!modelValues?.length && isTranslated) {
          isTranslated = false;
        }
      });

      return isTranslated;
    },
  },

  getters: {
    getAppearances() {
      return (primaryColor) => {
        const tokenColors = useTokenColors(primaryColor);
        return [
          {
            id: 'light',
            text: useT('abc658'),
            thumbnail: '/img/appearance/light.svg',
          },
          {
            id: 'lightGray',
            text: useT('abc659'),
            thumbnail: '/img/appearance/light-gray.svg',
          },
          {
            id: 'dark',
            text: useT('abc660'),
            thumbnail: '/img/appearance/dark.svg',
          },
          {
            id: 'darkGray',
            text: useT('abc661'),
            thumbnail: '/img/appearance/dark-gray.svg',
          },
          {
            id: 'accent',
            text: useT('abc662'),
            thumbnail: `/api/public/colorized.svg?path=/img/appearance/accent.svg&color=${encodeURIComponent(tokenColors?.colorPalette.value['builder-primary-500'])}`,
          },
          {
            id: 'lightAccent',
            text: useT('abc663'),
            thumbnail: `/api/public/colorized.svg?path=/img/appearance/light-accent.svg&color=${encodeURIComponent(tokenColors?.colorPalette.value['builder-primary-100'])}`,
          },
        ];
      };
    },

    getContactLinkType(state) {
      return (id) => {
        return state.options.contactLinkType.find((link) => link.id === id) || null;
      };
    },

    getSectionName() {
      return (sectionType) => {
        const sectionNamespaces = [
          {
            type: 'agendaBasic',
            name: useT('xyz157'),
          },
          {
            type: 'barBasic',
            name: useT('abc712'),
          },
          {
            type: 'barNavigation',
            name: useT('abc713'),
          },
          {
            type: 'collapsibleBasic',
            name: useT('abc689'),
          },
          {
            type: 'dividerBasic',
            name: useT('abc690'),
          },
          {
            type: 'formBasic',
            name: useT('abc686'),
          },
          {
            type: 'gridBasic',
            name: useT('abc688'),
          },
          {
            type: 'headerBasic',
            name: useT('abc714'),
          },
          {
            type: 'headerBottomBox',
            name: useT('abc715'),
          },
          {
            type: 'headerTopBlock',
            name: useT('abc716'),
          },
          {
            type: 'imageBasic',
            name: useT('abc717'),
          },
          {
            type: 'imageNarrow',
            name: useT('abc718'),
          },
          {
            type: 'mapBasic',
            name: useT('abc674'),
          },
          {
            type: 'speakersBasic',
            name: useT('xyz158'),
          },
          {
            type: 'sponsorsBasic',
            name: useT('xyz164'),
          },
          {
            type: 'textBasic',
            name: useT('abc719'),
          },
          {
            type: 'textNarrow',
            name: useT('abc720'),
          },
          {
            type: 'textTwoColumns',
            name: useT('abc721'),
          },
          {
            type: 'textWithImage',
            name: useT('abc722'),
          },
        ];

        return sectionNamespaces.find((sectionNamespace) => sectionNamespace.type === sectionType)?.name;
      };
    },

    getDummyGuest() {
      return (lang, customProperties) => {
        const properties = {
          firstName: useT('xyz94', {}, { locale: lang || 'en' }),
          lastName: useT('xyz95', {}, { locale: lang || 'en' }),
          email: useT('xyz96', {}, { locale: lang || 'en' }),
          lang,
        };

        (customProperties || useEventStore().event?.guestlist?.properties || []).forEach((property) => {
          if (property.type === 'text') {
            properties[property.id] = useT('xyz97', {}, { locale: lang || 'en' });
          }

          if (property.type === 'select') {
            properties[property.id] = property.options?.[0]?.id;
          }

          if (property.type === 'multiSelect') {
            properties[property.id] = property.options?.slice(0, 2).map((option) => option?.id);
          }
        });

        return {
          _id: '1234567890',
          event: useEventStore().event?._id,
          properties,
          status: {
            confirmation: 0,
            attendance: 0,
          },
          guestAppData: {
            accessCode: 123456,
          },
        };
      };
    },
  },
});
